<template>
  <v-dialog
    width="700"
    persistent
    transition="slide-x-transition"
    v-model="get_modal_view_configurarempresa"
  >
    <v-card class="expande-horizontal wrap">
      <div class="expande-horizontal px-1 pr-3 py-3">
        <v-btn dark @click="fecha_modal_view_configurarempresa" icon>
          <v-icon color="grey">
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <span style="font-size: 17pt; width: 100%;" class="fonte">
          Novo Link
        </span>
        <div class="expande-horizontal"></div>
        <v-btn
          class="elevation-3"
          icon
          @click="fecha_modal_view_configurarempresa"
        >
          <v-icon color="red">
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <div class="expande-horizontal wrap py-6 pt-0 pb-0">
        <v-flex class="pa-3" xs12>
          <div class="expande-horizontal column">
            <!-- <modal-send-archive /> -->
            <v-form ref="form">
              <v-flex class="px-1" xs12>
                <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                  Nome
                </span>
                <v-text-field
                  v-model="novo_link.nome"
                  dense
                  solo
                  flat
                  outlined
                  :color="$theme.primary"
                  label="ex: Whatsapp"
                ></v-text-field>
              </v-flex>

              <v-flex class="px-1" xs12>
                <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                  Link
                </span>
                <v-text-field
                  v-model="novo_link.link"
                  dense
                  solo
                  flat
                  outlined
                  :color="$theme.primary"
                  label="ex: https://..."
                ></v-text-field>
              </v-flex>

              <v-flex class="px-1" xs12>
                <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                  Visível?
                </span>
                <v-switch
                  v-model="novo_link.visivel"
                  :color="$theme.primary"
                ></v-switch>
              </v-flex>
            </v-form>
          </div>
        </v-flex>
      </div>

      <div class="expande-horizontal">
        <v-btn large dark @click="valida_form" tile block color="green">
          <span class="fonte"> Salvar </span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalSendArchive from "../../shared/components/ModalSendArchive.vue";
export default {
  components: { ModalSendArchive },
  data() {
    return {
      novo_link: {}
    };
  },
  computed: {
    ...mapGetters([
      "get_configurarempresa",
      "get_modal_view_configurarempresa",
      "getLoggedUser"
    ])
  },
  methods: {
    ...mapActions([
      "criar_configurarempresa",
      "atualizar_configurarempresa",
      "fecha_modal_view_configurarempresa"
    ]),
    valida_form() {
      if (this.$refs.form.validate()) {
        this.$store.commit("set_configurarempresa", {
          ...this.get_configurarempresa,
          links: [...this.get_configurarempresa.links, this.novo_link]
        });
        this.novo_link = {};
        this.fecha_modal_view_configurarempresa();
      }
    }
  }
};
</script>
